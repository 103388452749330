import Greeting from '../Greetings/Greetings'
import './App.css'
import React from 'react'

function App() {
  return (
    <div className="App">
      <Greeting />
    </div>
  )
}

export default App
