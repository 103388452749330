import React, { useEffect } from 'react'

const greetings = [
  { lang: 'English', message: 'Hello!' },
  { lang: 'French', message: 'Bonjour!' },
  { lang: 'German', message: 'Guten Tag!' },
  { lang: 'Spanish', message: 'Hola!' },
  { lang: 'Italian', message: 'Ciao!' },
  { lang: 'Japanese', message: 'こんにちは!' },
  { lang: 'Korean', message: '안녕하세요!' },
  { lang: 'Mandarin', message: '你好!' },
  { lang: 'Danish', message: 'Hej!' },
  { lang: 'Ukrainian', message: 'Привіт!' },
  { lang: 'Indonesian', message: 'Halo!' },
  { lang: 'Arabian', message: 'صباح الخير' },
]

const Greeting = () => {
  const domainTitleMap = {
    'mirete.fr': 'mirete.fr',
    'roarth.fr': 'roarth.fr',
  }

  const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)]
  const domain = window.location.hostname
  const domainTitle = domainTitleMap[domain] || ''

  useEffect(() => {
    document.title = `${randomGreeting.message} - ${domainTitle}`
  }, [randomGreeting.message, domainTitle])

  return <div>{randomGreeting.message}</div>
}

export default Greeting
